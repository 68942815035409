<template>
  <v-row align="stretch" align-content="start" justify="center" no-gutters>
    <v-col>
      <ButtonList />
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ButtonList from '@/components/ButtonList.vue'

export default {
  name: 'SavedButtons',
  components: {
    ButtonList,
  },
}
</script>
